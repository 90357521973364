import React from "react";
import { Link } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";
import $ from "jquery";

//redux
import { connect } from "react-redux";
import {
  fetchQuoteById,
  showHideFooter,
  showHideTopNav,
  showHideSidebar,
  handlePercentageText,
  saveQuote,
} from "../../actions";

//objects
import { Quote_Object } from "../objects/Quote";

//components
import StepPreview from "./blocks/previews/StepPreview";
import TermsPreview from "./blocks/previews/TermsPreview";
import Signature from "./blocks/Signature";
import Loading from "../components/Loading";

import { months } from "../utils/time";
import { commaFormatting } from "../utils/commaformatting";
import TotalsSection from "./blocks/previews/TotalsSection";
const smartquotes = require("smartquotes");
class QuotePreview extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      quote: new Quote_Object(),
      num_of_pages: 0,
      num_of_steps: 0,
      last_saved: "",
      new_page_number: 0,
      changeNumber: false,
    };
  }

  async componentDidMount() {
    //hiding sidebar and topnav
    this._isMounted = true;
    if (this._isMounted) {
      this.props.showHideTopNav();
      this.props.showHideSidebar();
      this.props.showHideFooter();
    }
    const { quote_id } = this.props.match.params;
    if (quote_id !== undefined) {
      await this.props.fetchQuoteById(quote_id);
      if (this.props.quote) {
        let quoteTmp = { ...this.props.quote };
        if (quoteTmp.scope.length < 15) {
          quoteTmp.scope = null;
        }
        if (quoteTmp.objectives.length < 15) {
          quoteTmp.objectives = null;
        }
        if (quoteTmp.key_dates.length < 15) {
          quoteTmp.key_dates = null;
        }

        let date_created = new Date(quoteTmp.date_created);
        quoteTmp.date_created =
          date_created.getDate() +
          " " +
          months[date_created.getMonth()] +
          " " +
          date_created.getFullYear();
        this.setState({
          isLoaded: true,
          quote: quoteTmp,
        });
        document.title =
          // quoteTmp.reference.company_acronym +
          "Q" +
          quoteTmp.reference.quotation_number +
          "-" +
          quoteTmp.reference.quotation_date;
      }
    }
    smartquotes();
  }
  componentDidUpdate(previousProps, previousState) {
    if (this.state.num_of_pages !== previousState.num_of_pages) {
      this.setState({
        num_of_pages: this.state.num_of_pages,
      });
    }
  }
  componentWillUnmount() {
    document.title = "Stepworks Quote Engine";
    console.log(this.props.discountPercentageShow);
    this._isMounted = false;
  }

  calculateTotalBeforeTax = () => {
    const { quote } = this.state;
    return (
      parseFloat(quote.duties_percentage / 100) * parseFloat(quote.total) +
      parseFloat(quote.total) -
      parseFloat(quote.discount)
    );
  };

  calculateTotalBeforeDiscount = () => {
    const { quote } = this.state;
    return (
      parseFloat(quote.duties_percentage / 100) * parseFloat(quote.total) +
      parseFloat(quote.total)
    );
  };

  calculateGST = () => {
    return this.calculateTotalBeforeTax() * 0.09;
  };

  calculateGrandTotal = () => {
    const { quote } = this.state;
    return (
      this.calculateTotalBeforeTax() +
      (quote.entity === "sg" ? this.calculateGST() : 0)
    );
  };

  handleContentChange(content) {
    let quoteTmp = { ...this.state.quote };
    const index = quoteTmp.content.findIndex((cont) => cont.id === content.id),
      c = [...quoteTmp.content];
    c[index] = content;
    //calculate new price on step change
    let step_sum = parseFloat(0);
    quoteTmp.content.map((step) => {
      step_sum += parseInt(step.base_price);
    });

    quoteTmp.total = step_sum;
    this.setState({
      quote: quoteTmp,
    });
    // this.getPageBreaks($("#quote").outerHeight());
  }

  // getPageBreaks(height) {
  //   $(".page-break").remove();
  //   $(".footer_right_always").remove();
  //   let a4 = 297;
  //   let quote = { ...this.state.quote };
  //   let height_in_mm = parseInt(height * 0.264583333);
  //   let num_pages = 0;
  //   if (height_in_mm > a4) {
  //     if (this.state.new_page_number !== 0)
  //       num_pages = this.state.new_page_number;
  //     else num_pages = parseInt(height_in_mm / a4);
  //     for (let i = 0; i < num_pages; i++) {
  //       if (quote.revision_version > 0) {
  //         $("#quote").append(
  //           '<div id="footer_right_always' +
  //             i +
  //             '" className="footer_right_always"><p>' +
  //             // quote.reference.company_acronym +
  //             "Q" +
  //             quote.reference.quotation_number +
  //             "." +
  //             quote.revision_version +
  //             "/" +
  //             quote.reference.quotation_date +
  //             '</p><p>Page <span className="current_page"></span> of ' +
  //             num_pages +
  //             "</p></div>"
  //         );
  //       } else {
  //         $("#quote").append(
  //           '<div id="footer_right_always' +
  //             i +
  //             '"  class="footer_right_always"><p>' +
  //             // quote.reference.company_acronym +
  //             "Q" +
  //             quote.reference.quotation_number +
  //             "/" +
  //             quote.reference.quotation_date +
  //             '</p><p>Page <span class="current_page"></span> of ' +
  //             num_pages +
  //             "</p></div>"
  //         );
  //       }
  //     }
  //     this.setState({
  //       num_of_pages: num_pages,
  //     });
  //   }
  // }

  handleUpdatePageNumber = (event) => {
    if (!isNaN(event.target.value)) {
      this.setState({
        new_page_number: parseInt(event.target.value),
      });
    }
  };
  handleConfirmPageNumber = () => {
    this.setState({
      new_page_number: this.state.new_page_number,
      changeNumber: false,
    });
  };
  handleTermsChange(terms) {
    let quoteTmp = { ...this.state.quote };
    quoteTmp.terms = terms;
    this.setState({
      quote: quoteTmp,
    });
  }

  // handleSave = async (event) => {
  //   if (event) event.preventDefault();
  //   if (this.state.quote.id !== -1) {
  //     await this.props.saveQuote(this.state.quote.id);

  //     if (this.props.saved_quote) {
  //       this.setState({
  //         saved: true,
  //         notification: true,
  //         last_saved: new Date(),
  //       });
  //     }
  //   }
  // };

  handleChangeQuill = (name) => (event) => {
    let quoteTmp = { ...this.state.quote };
    quoteTmp[name] = event;
    this.setState({
      quote: quoteTmp,
    });
  };

  render() {
    let { quote, isLoaded, last_saved, changeNumber, num_of_pages } =
      this.state;
    if (!isLoaded) {
      return <Loading message="Loading ..." gif="/images/stepworks.gif" />;
    } else {
      return (
        <div className="quote_preview container">
          <div className="quote_header">
            <div className="content">
              <Link to={"/quote/" + quote.version_quote_id}>
                <button
                  className=""
                  onClick={() => {
                    this.props.showHideTopNav();
                    this.props.showHideSidebar();
                    this.props.showHideFooter();
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </button>
              </Link>
              <button
                onClick={() => {
                  //this.handleSave();
                  // this.getPageBreaks($("#quote").outerHeight());
                  window.print();
                }}
              >
                <FontAwesomeIcon icon="file-export" />
              </button>
              {/* <div className="quote_settings quote_preview_settings">
                <button
                  className="float-right"
                  onClick={() => {
                    // this.getPageBreaks($("#quote").outerHeight());
                    this.setState({ changeNumber: !this.state.changeNumber });
                  }}
                >
                  <FontAwesomeIcon icon="tools" />
                  <FontAwesomeIcon icon="sort-numeric-down" />
                </button> */}
              {/* {changeNumber && (
                  <div className="settings_box">
                    <label className="label_change_num"># of pages:</label>
                    <input
                      className="number"
                      type="number"
                      min="1"
                      placeholder={num_of_pages}
                      onChange={this.handleUpdatePageNumber}
                    />
                    <br />
                    <br />
                    <button
                      className="cancel"
                      onClick={() => {
                        this.setState({
                          changeNumber: !this.state.changeNumber,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon="times" />
                    </button>
                    <button
                      className="confirm float-right"
                      onClick={this.handleConfirmPageNumber}
                    >
                      <FontAwesomeIcon icon="check" />
                    </button>
                  </div>
                )} */}
              {/* </div> */}
              {/* Change number of pages: <input type='number' placeholder={this.state.num_of_pages} value={num_of_pages} onChange={this.changeNumOfPages}/> */}
              <div className="notification_box">
                {last_saved !== "" && (
                  <p>
                    Quote was last saved on: {last_saved.getDate()}.
                    {last_saved.getMonth() + 1}.{last_saved.getFullYear()} at{" "}
                    {last_saved.getHours()}:{last_saved.getMinutes()}:
                    {last_saved.getSeconds()}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div id="footer_left_once">
            <p>
              {quote.entity === "sg"
                ? "160 Robinson Road, #14-04 Singapore 068914"
                : "19/F, LKF29, 29 Wyndham Street, Central, Hong Kong"}
              <br />
              stepworks.co
            </p>
          </div>

          <div id="quote" className="content">
            <div className="stepworks_logo">
              <img src="/images/logo.jpg" />
            </div>
            <div className="quote_details_wrapper">
              <div className="quote_details">
                <section>
                  <div className="row basic">
                    <table>
                      <tbody>
                        <tr>
                          <td>To:</td>
                          <td>
                            <span>{ReactHtmlParser(quote.company)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td>{ReactHtmlParser(quote.company_address)}</td>
                        </tr>
                        <tr>
                          <td>Attn:</td>
                          <td>{ReactHtmlParser(quote.attn)}</td>
                        </tr>
                        {/* <tr className="height20" /> */}
                        <tr>
                          <td>Date:</td>
                          <td>{quote.date_created}</td>
                        </tr>
                        <tr>
                          <td>Reference:</td>
                          <td>
                            <span>
                              Q{quote.reference.quotation_number}
                              {parseInt(quote.revision_version) !== 0 &&
                                "." + quote.revision_version}
                              /{quote.reference.quotation_date}
                            </span>
                          </td>
                        </tr>
                        {quote.nda && (
                          <>
                            <tr className="height20" />
                            <tr>
                              <td>Confidentiality:</td>
                              <td>
                                <span>NDA signed</span>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr className="height20" />
                        <tr>
                          <td className="project_name">
                            <strong>Project:</strong>
                          </td>
                          <td>
                            <strong>{quote.project_name}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              <div className="quote_title">
                <h1 className="main">Quotation</h1>
                <h1 className="second_main">Confidential</h1>
              </div>
            </div>
            {quote.scope !== null && (
              <div className="row intro">
                <h2>Overview</h2>
                {ReactHtmlParser(quote.scope)}
              </div>
            )}
            {quote.objectives !== null && (
              <div className="row objectives">
                <h2>Objectives</h2>
                {ReactHtmlParser(quote.objectives)}
              </div>
            )}
            {quote.key_dates !== null && (
              <div className="row keydates">
                <h2>Key dates</h2>
                {ReactHtmlParser(quote.key_dates)}
              </div>
            )}
            {quote.content.length !== 0 && (
              <section className="blocks">
                <br />
                <div className="no_page_break">
                  <h2>
                    Scope of work and fee
                    <span style={{ float: "right" }}>
                      {quote.currency.name}
                    </span>
                  </h2>
                </div>
                <div className="row">
                  <div className="steps">
                    {quote.content !== null &&
                      quote.content.map((step, i) => {
                        return (
                          <StepPreview
                            key={i}
                            handleContentChange={this.handleContentChange.bind(
                              this
                            )}
                            content={step}
                            currency={quote.currency}
                            step_index={step.order}
                            num_of_steps={quote.content.length}
                          />
                        );
                      })}
                  </div>
                </div>
              </section>
            )}

            <div className="project_management_fee no_page_break_pm">
              {parseInt(quote.duties_percentage) !== 0 && (
                <section className="pm">
                  <div className="row">
                    <div className="duties">
                      <div className="">
                        <h3>Project and account management</h3>
                        <div
                          className="duties_item"
                          style={{ flexWrap: "wrap" }}
                        >
                          {console.log(quote.account_management_text)}
                          {quote.account_management_text != "<p><br></p>" && (
                            <div className="row intro">
                              {ReactHtmlParser(quote.account_management_text)}
                            </div>
                          )}
                          <div className="row intro">
                            <p>
                              Overall project planning, project management and
                              account management duties charged at{" "}
                              <span className="pmcut">
                                {quote.duties_percentage}
                              </span>
                              % of the total creative fee&nbsp;
                              <span className="no_bold">
                                ({quote.currency.symbol}
                                {commaFormatting(quote.total)}
                                <span className="currency_symbol" />
                                <span className="subtotal" />)
                              </span>
                            </p>
                          </div>
                          <div
                            className="number"
                            style={{
                              display: "flex",
                              flex: "0 0 100%",
                              marginTop: "10px",
                              justifyContent: "space-between",
                              borderBottom: "1px solid black",
                              float: "none",
                              marginBottom: "10px",
                            }}
                          >
                            <span>
                              Subtotal for project and account management{" "}
                            </span>
                            <span>
                              {quote.currency.symbol}
                              {commaFormatting(
                                (quote.duties_percentage / 100) * quote.total
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {quote.entity === "sg" && parseInt(quote.discount) === 0 && (
                <>
                  <TotalsSection
                    title="Total"
                    excl="(before tax)"
                    currencyCode={quote.currency.symbol}
                    amount={this.calculateTotalBeforeTax()}
                    entity={quote?.entity}
                    className=""
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                  />

                  <TotalsSection
                    title="GST"
                    excl="(9%)"
                    currencyCode={quote.currency.symbol}
                    entity={quote?.entity}
                    amount={this.calculateGST()}
                    className="gst no-underline"
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                  />
                </>
              )}
              <TotalsSection
                title={parseInt(quote.discount) === 0 ? "Grand total" : "Total"}
                excl={parseInt(quote.discount) !== 0 ? "(before discount)" : ""}
                entity={quote?.entity}
                currencyCode={parseInt(quote.discount) === 0 ? quote.currency.name : quote.currency.symbol}
                amount={parseInt(quote.discount) === 0 ? this.calculateGrandTotal() : this.calculateTotalBeforeDiscount()}
                className=""
                style={{ marginBottom: "1px" }}
              />

              {parseInt(quote.discount) !== 0 && (
                <>
                  <TotalsSection
                    title={this.props.discountPercentageShow === 'visible' ? `${(
                      (quote.discount * 100) /
                      (parseFloat(quote.duties_percentage / 100) *
                        parseFloat(quote.total) +
                        parseFloat(quote.total))
                    ).toFixed(0)}%  ${
                      quote.discount_text || "discount (rounded-up)"
                    }` : "Discount (rounded-up)"}
                    excl={``}
                    currencyCode={quote.currency.symbol}
                    amount={quote.discount}
                    discountCopy={quote.discount_copy}
                    className="discount_section no-underline"
                    style={{ marginBottom: "10px" }}
                    entity={quote?.entity}
                  />

                  {quote.entity === "sg" && (
                    <>
                      <TotalsSection
                        title="Total"
                        excl="(before tax)"
                        currencyCode={quote.currency.symbol}
                        amount={this.calculateTotalBeforeTax()}
                        className=""
                        entity={quote?.entity}
                        style={{ marginBottom: "0px" }}
                      />
                      <TotalsSection
                        title="GST"
                        excl="(9%)"
                        currencyCode={quote.currency.symbol}
                        amount={this.calculateGST()}
                        className="gst no-underline"
                        entity={quote?.entity}
                        style={{ marginBottom: "0px" }}
                      />
                    </>
                  )}

                  <TotalsSection
                    title="Grand Total"
                    excl=""
                    currencyCode={quote.currency.name}
                    amount={this.calculateGrandTotal()}
                    className=""
                    entity={quote?.entity}
                    style={{ marginTop: "0px" }}
                  />
                </>
              )}
            </div>
            {quote.optional_items.length !== 0 && (
              <div className="optional_items">
                {quote.optional_items[0].blocks.lenght !== 0 && ( //checking only for the first one
                  <section className="optional_items_section">
                    <h3 className="optional_items_heading no_margin_bottom">
                      Optional items
                    </h3>
                    <p className="optional_items_note">
                      (Does not include 15% account and project management fee)
                    </p>
                    <div className="optional_items">
                      {quote.optional_items !== null &&
                        quote.optional_items.map((step, i) => {
                          return (
                            <StepPreview
                              key={i}
                              content={step}
                              currency={quote.currency}
                              step_index={step.order}
                              num_of_steps={quote.content.lenght}
                            />
                          );
                        })}
                    </div>
                  </section>
                )}
              </div>
            )}
            <div className="terms_section">
              <TermsPreview
                terms={quote.terms}
                handleTermsChange={this.handleTermsChange.bind(this)}
              />
            </div>
            <div className="signature_section">
              <Signature user={quote.author} entity={quote?.entity} />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    quote: state.quotes.quote,
    saved_quote: state.quotes.saved_quote,
    discountPercentageShow: state.global.discountPercentageShow,
  };
};

export default connect(mapStateToProps, {
  saveQuote,
  fetchQuoteById,
  showHideFooter,
  handlePercentageText,
  showHideSidebar,
  showHideTopNav,
})(QuotePreview);
